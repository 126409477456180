<template>
  <div class="container_login">
    <form @submit.prevent="sendform" v-if="!resetPassword">
      <router-link :to="{ name: 'home' }">
        <div class="container_login__logo mb-3">
          <div class="container_login__logo--img">
            <img src="@/assets/img/do-genius-on.svg" class="img-fluid">
          </div>
          <div class="container_login__logo--text">
            <h5>
              BUILDING <br>
              TECHNOLOGY <br>
              FOR TECHNOLOGY <br>
              BUILDERS
            </h5>
          </div>
        </div>
      </router-link>
        <div class="mb-3 container_input">
          <input type="email" placeholder="Email" class="form-control" :class="{'is-invalid': $v.form.email.$error || errorLogin || disabledAccount}"
            v-model.trim="$v.form.email.$model">

          <span role="alert" class="invalid-feedback">
            <strong v-if="!$v.form.email.required && !errorLogin">The Email is required</strong>
            <strong v-if="!$v.form.email.email && !errorLogin">Invalid Email format</strong>
            <strong v-if="!$v.form.email.maxLength">
              The Email must be less than {{$v.form.email.$params.maxLength.max}} letters
            </strong>
            <strong v-if="errorLogin && $v.form.email.required && $v.form.email.email">These credentials do not match our records</strong>
            <strong v-if="disabledAccount && $v.form.email.required && $v.form.email.email">Account is temporarily disabled</strong>
          </span>
        </div>

        <div class="mb-3 container_input">
          <input type="password" placeholder="Password" class="form-control"
                :class="{'is-invalid': $v.form.password.$error}"
                 v-model.trim="$v.form.password.$model">

          <span role="alert" class="invalid-feedback" v-if="$v.form.password.$error">
            <strong v-if="!$v.form.password.required">The Password is required</strong>
            <strong v-if="!$v.form.password.minLength">
              The Password must have at least {{$v.form.password.$params.minLength.min}} characters
            </strong>
            <strong v-if="!$v.form.password.maxLength">
              The Password must be less than {{$v.form.password.$params.maxLength.max}} characters
            </strong>
          </span>
        </div>
        <div class="container_login__form--btn mb-3 container_input">
            <button type="submit" class="btn">Login</button>
        </div>
        <div class="container_login__form--link container_input">
          <router-link :to="{ name: 'password.reset' }">
            Forgot my password
          </router-link>
        </div>
      </form>
      <ChangePassword v-else :token="token" :current_password="form.password"/>
    <Footer/>
    <b-overlay :show="load" rounded="sm" no-wrap></b-overlay>
  </div>
</template>

<script>
import {required, email, minLength, maxLength} from 'vuelidate/lib/validators';

import ChangePassword from '@/views/Auth/ChangePassword.vue';
import Footer from '@/components/FooterNewsRoom.vue';

export default {
  name: 'Login',
  components: {
    ChangePassword,
    Footer,
  },
  data() {
    return {
      form: {
        email: String(),
        password: String(),
      },
      load: false,
      errorLogin: false,
      resetPassword: false,
      disabledAccount: false,
      token: String()
    };
  },
  beforeCreate() {
    if (this.$session.exists()) {
      this.$router.push({ name: 'dashboard' });
    }
  },
  validations: {
    form: {
      email: {
        required,
        email,
        maxLength: maxLength(60),
      },
      password: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(30),
      },
    },
  },
  methods: {
    sendform() {
      this.$v.form.$reset();
      this.$v.form.$touch();
      if (!this.$v.form.$invalid) {
        this.load = true;
        this.$http.post('login', this.form).then((response) => {
          if (response.status === 200) {
            if (!!response.body.data.email_verified_at) { // is not null
              this.token = response.body.token;
              this.resetPassword = true;
            }else if(!response.body.data.status){
              this.disabledAccount = true;
            }else{
              this.$session.start();
              this.$session.set('jwt', response.body.token);
              this.$session.set('userInfo', response.body.data);
              this.$router.push({ name: 'dashboard' });
            }
            this.load = false;
          }
        }, (error) => {
          if (error.status === 400) {
            if (error.body.response === 'invalid_credentials') {
              this.errorLogin = true;
              this.load = false;
            }
            if (error.status === 500) {
              this.$notify({ group: 'app', text: 'An error occurred while trying to log in. Try again.', type: 'error' });
            }
          }
        });
      }
    },
  },
};
</script>
