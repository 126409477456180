<template>
  <form @submit.prevent="resetPassword">
    <router-link :to="{ name: 'home' }">
      <div class="container_login__logo mb-3">
        <div class="container_login__logo--img">
          <img src="@/assets/img/do-genius-on-white.svg" class="img-fluid">
        </div>
        <div class="container_login__logo--text">
          <h5>
            BUILDING <br>
            TECHNOLOGY <br>
            FOR TECHNOLOGY <br>
            BUILDERS
          </h5>
        </div>
      </div>
    </router-link>
    <div class="mb-3">
      <input type="password" placeholder="New password" class="form-control"
        :class="{'is-invalid': $v.form.password.$error || (!checkPassword.success && form.password != '' && form.password != null)}"
        v-model.trim="$v.form.password.$model">
      <span role="alert" class="invalid-feedback" v-if="$v.form.password.$error">
        <strong v-if="!$v.form.password.required">The password is required</strong>
      </span>
      <span class="validation_password mt-1" v-if="form.password != '' && form.password != null">
        <span class="validation_password--text" :class="[checkPassword.level]">
          {{checkPassword.title}}
        </span>
        <span class="validation_password--description">
          {{checkPassword.description}}
        </span>
      </span>
    </div>
    <div class="mb-3">
      <input type="password" placeholder="Confirm new password" class="form-control"
        :class="{'is-invalid': $v.form.password_confirmation.$error}"
        v-model.trim="$v.form.password_confirmation.$model">
        <span role="alert" class="invalid-feedback" v-if="$v.form.password_confirmation.$error">
          <strong v-if="!$v.form.password_confirmation.required">The confirm new password is required</strong>
          <strong v-if="!$v.form.password_confirmation.sameAsPassword && $v.form.password_confirmation.required">The password confirmation does not match</strong>
        </span>
    </div>
    <div class="container_login__form--btn mb-3">
        <button type="submit" class="btn">Change password</button>
    </div>
  </form>
</template>
<script>
import {required, sameAs, minLength, maxLength} from 'vuelidate/lib/validators';
export default {
  name: 'Change_password',
  props: {
    current_password: {
      type: String,
      required: true
    },
    token: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      form: {
        current_password: this.current_password,
        password: null,
        password_confirmation: null
      },
      level_password: {
        text: null,
        description: null,
        level: 'level_1'
      },
      btnChangePassword: false
    };
  },
  validations: {
    form: {
      password: {
        required,
        minLength: minLength(8),
        maxLength: maxLength(60),
        isValid(value){
          return this.checkLevelPassword(value).success;
        }
      },
      password_confirmation: {
        required,
        sameAsPassword: sameAs('password')
      },
    },
  },
  methods: {
    resetPassword(){
      this.$v.form.$reset();
      this.$v.form.$touch();
      if (!this.$v.form.$invalid) {
        this.$parent.load = true;
        this.$http.post('reset_password', this.form, { headers: { Authorization: `Bearer ${this.token}` } }).then((response) => {
          if (response.status === 200) {
            this.$session.start();
            this.$session.set('jwt', this.token);
            this.$session.set('userInfo', response.body.data);
            this.$router.push({ name: 'dashboard' });
          }
          this.$parent.load = false;
        }, (error) => {
          if (error.status == 400) {
            this.$notify({ group: 'app', text: error.body.message, type: 'warn' });
          }
          if (error.status === 500) {
            this.$notify({ group: 'app', text: 'An error occurred while trying to change the password. Try again.', type: 'error' });
          }
          this.$parent.load = false;
        })
      }
    }
  },
  computed: {
    checkPassword(){
      return this.checkLevelPassword(this.form.password);
    }
  },
};
</script>
